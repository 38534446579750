import { ReactComponent as Leer } from '../assets/leer.svg'

const descriptions = [
    "Constantes, de gran coraje y honestidad. No son tova mokõi.",
    "Idealistas, generosos, independientes y bien rova ́atã.",
    "Tienen un sexto sentido, optimistas, alegres y muy pastelosos.",
    "Seguros de sí, perfeccionistas y hasẽngy de vez en cuando.",
    "Púa tarara pero prácticos, justos y firmes.",
    "Impulsivos pero equilibrados, ha ́ekuéra ibuenoiterei.",
    "Emotivos, super juky, sinceros y nobles.",
    "Saben lo que quieren, audaces, batalladores y algo bohemios.",
    "Tradicionales, tienen buen juicio, sensibles y akãhatã.",
    "Románticos, sentimentales, fuertes pero hasẽngy.",
    "Fogosos, de fuerza espiritual y muy kachiãi.",
    "Ambiciosos, materialistas pero solidarios, son hechakuaa."
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Card(props) {
    return (
        <button onClick={() => props.setOpen(props.id)} className="max-w-7xl py-8 flex flex-wrap flex-shrink-0 z-20 px-4 bg-card border border-card sm:px-6 hover:border hover:border-fontB">
            <div className="flex flex-col w-2/3 items-start my-auto">
                <p className="text-fontA text-sm tracking-wider font-arsenal font-medium">NACIDOS EN {props.month} </p>
                <div className={classNames(props.id == 6 || props.id == 9 || props.id == 11 ? '-mt-1 -mb-2' : '', 'mt-2')}>{props.sign}</div>
                <div className='w-4/5 h-10'>
                    <p className='text-xs mt-2 text-fontA tracking-wider font-arsregular text-start'>{descriptions[props.id]}</p>
                </div>
            </div>
            <div className="w-1/3 my-auto pl-12">
                <Leer />
            </div>
        </button >
    )
}